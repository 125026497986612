
window.axios = require('axios');
require('./testi-carousel')
require('./parallax')
require('./smooth-scroll')

import Vue from 'vue';


window.onscroll = function() {
    let form = document.querySelector('form')
    let fromTop = window.pageYOffset
    let position = form.getBoundingClientRect().top
    let height = window.innerHeight
    if( position + 200 <= height ){
        form.classList.remove('close-screen')
        form.classList.add('open-screen')
    }
}

const app = new Vue({
    el: '#form-app',
    data: {
        nom: '',
        email: '',
        message: '',
        sending: false,
        messageSent: false,
        error: ''
    },
    methods: {
        send () {
            this.sending = true
            this.error = ''
            let data = {
                nom: this.nom,
                email: this.email,
                message: this.message
            }
            if(!data.email){
                this.error = 'Veuillez encoder votre email'
                this.sending = false
            }else{
                axios.post('https://cj990crmk4.execute-api.eu-central-1.amazonaws.com/prod', data).then(() => {
                    setTimeout(() =>{
                        this.sending = false
                        this.messageSent = false
                        setTimeout(() =>{
                            this.messageSent = false
                            let form = document.querySelector('form')
                            form.classList.remove('open-screen')
                            form.classList.add('close-screen')
                        }, 100);
                    }, 4900);
                }).catch((e) => {
                    this.error = 'Une erreur s\'est produite. Veuillez réessayer.'
                    this.sending = false
                })
            }
        },
        inputNotEmpty (e) {
            if (e.target.value !== undefined && e.target.value !== '') {
                e.target.parentElement.children[1].classList.add('not-empty')
            } else {
                e.target.parentElement.children[1].classList.remove('not-empty')
            }
        }
    }
});