import Swiper from 'swiper/swiper-bundle.min.js'

let mySwiper = new Swiper('.swiper-container', {
  slidesPerView: 3,
  spaceBetween: 30,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    0:{
      slidesPerView: 1
    },
    1050: {
      slidesPerView: 3
    }
  }
})
